
import { defineComponent, onMounted } from "vue";
import KTTableWidgetHealthRatios from "@/components/widgets/tables/WidgetHealthRatios.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "daily-cash-flow",
  components: {
    KTTableWidgetHealthRatios,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Financial Health Ratios", ["Financial Statements","Financial Health Ratio"]);
    });
  },
});
